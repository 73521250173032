<template id="modal-template">
    <transition v-if="show" name="modal" appear>
        <div class="modal-container">
            <div class="modal-wrapper">
                <div class="modal">
                    <div class="modal__head">
                        <h1 class="modal__title">문의하기</h1>
                        <button class="modal__close" type="button" @click="close">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <path d="M0.222,1.636 L1.636,0.221 L15.778,14.363 L14.364,15.778 L0.222,1.636 Z"/>
                                <path d="M14.364,0.221 L15.778,1.636 L1.636,15.778 L0.222,14.363 L14.364,0.221 Z"/>
                            </svg>
                        </button>
                    </div>
                    <div class="modal__body">
                        <div class="modal-inquiry-container">
                            <div class="modal-inquiry">
                                <h2 class="modal-inquiry__name"></h2>
                                <form class="modal-inquiry__form">
                                    <div class="modal-inquiry__form-row">
                                        <input v-model="inquire.subject" class="input" type="text" name="" value="" placeholder="문의 제목을 입력해주세요.">
                                    </div>
                                    <div class="modal-inquiry__form-row">
                                        <textarea v-model="inquire.content" class="textarea" name="name" placeholder="문의 내용을 입력해주세요."></textarea>
                                    </div>
                                    <div class="modal-inquiry__form-row">
                                        <label class="check">
                                            <input v-model="inquire.secret" class="input" type="checkbox" name="" :value="true">
                                            <i class="icon"></i>
                                            <span class="text">비공개</span>
                                        </label>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal__foot modal__foot--border">
                        <div class="modal__buttons">
                            <button class="button button--primary" type="button" @click="submit"><span>문의하기</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default{
    props: {
        product: { type: Object, default: null }
    },
    data: () => {
        return {
            show: false,

            inquire: {
                subject: null,
                content: null,
                secret: false
            }
        }
    },
    methods: {
        open(){
            this.show = true;
            document.querySelector('html').setAttribute('data-modal', 'opened');
        },
        close(){
            this.show = false;
            document.querySelector('html').removeAttribute('data-modal');
        },
        async submit(){
            try{
                await this.postInquire(
                    Object.assign({
                        _product: this.product._id
                    }, this.inquire)
                );

                alert("문의가 완료되었습니다");
                this.$emit('submit');
                this.close();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },
        postInquire(inquire){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.post("/api/v1/me/inquires", inquire);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        }
    }
}
</script>
