<template>
    <div v-bind:id="`naver-pay-button-${uuid}`" oncontextmenu="return false;"></div>
</template>
<script>
import detect from "../../../plugins/detect";
let uuid = 0;
export default {
    beforeCreate() {
        uuid += 1;
    },
    props: {
        carts: { type: Array, default: [] },
        _product: { type: String, default: null },
        buttonKey: String,
        type: { type: String, default: 'C' },
        color: { type: Number, default: 2 },
        count: { type: Number, default: 2 },
        disabled: { type: Boolean, default: false },
        pc: { type: Boolean, default: false },
        mobile: { type: Boolean, default: false }
    },
    data: () => {
        return {
            uuid,
            detect
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            naver.NaverPayButton.apply({
				BUTTON_KEY: this.buttonKey,
				TYPE: this.type, //버튼 스타일
				COLOR: this.color, //버튼 색상타입
				COUNT: this.count, // 네이버페이버튼 + 찜하기버튼 모두 출력 여부
				ENABLE: this.disabled ? 'N' : 'Y', //네이버페이 활성여부(재고부족 등에는 N으로 비활성처리)
				EMBED_ID: `naver-pay-button-${this.uuid}`, //네이버페이 버튼 UI가 부착될 HTML element의 ID
				BUY_BUTTON_HANDLER: this.buy,
				WISHLIST_BUTTON_HANDLER: this.wishlist,
			});
        },
        async buy(){
            try{
                if(this.disabled) throw new Error("구매 불가 상품입니다");
                if(!this.carts.length) throw new Error("구매할 상품정보를 선택해주세요");

                var soldout = this.carts.find((cart) => cart.stock == 0);
				if(soldout) throw new Error("품절된 상품입니다");

				var carts = await this.postCarts(this.carts);
				if(!this.accessToken) this.$store.dispatch("addToCart", this.carts);

                var res = await this.$http.post("/api/naver/payment/orders", { _carts: carts.map((cart) => cart._id) });

                window.location.href = res.data.url;

                // if(this.pc) window.location.href = `${res.data.pcUrl}?ORDER_ID=${res.data.ORDER_ID}&SHOP_ID=${res.data.SHOP_ID}&TOTAL_PRICE=${res.TOTAL_PRICE}`;
                // if(this.mobile) window.location.href = `${res.data.mobileUrl}?ORDER_ID=${res.data.ORDER_ID}&SHOP_ID=${res.data.SHOP_ID}&TOTAL_PRICE=${res.TOTAL_PRICE}`;
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },
        async wishlist(){
            try{
                var res = await this.$http.post("/api/naver/payment/wishlist", { _product: this._product });

                switch(detect){
                    case "pc": {
                        window.open(res.data.pc_url, "wishlist", "scrollbars=yes,width=400,height=267");
                        break;
                    }
                    case "mobile": {
                        window.location.href = res.data.m_url;
                        break;
                    }
                }
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },
        postCarts(carts){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.post('/api/v1/me/carts', { carts });

                    resolve(res.data.carts);
                }
                catch(error){
                    reject(error);
                }
            })
        },
    }
}
</script>
