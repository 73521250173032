<template>
	<div class="wrap wrap--mypage">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
			<div class="container">
				<div class="mypage-container">
                    <!-- 배너 타이틀 -->
                    <div class="mypage-title">
                        <h1 class="text">마이페이지</h1>
                    </div>
					<!-- 네비게이션 메뉴 -->
					<nav class="mypage-navigation">
						<mypage-navigation></mypage-navigation>
					</nav>
					<!-- 페이지 콘텐츠 -->
					<div class="mypage-contents">
						<div class="mypage-contents__head">
                            <h2 class="mypage-contents__title">적립금</h2>
						</div>
						<div class="mypage-contents__body">
                            <div class="point-list-container">
								<!-- 상단 적립금 총액 -->
								<div class="point-list__head">
									<div class="point-list__panel">
										<div class="point-list__panel-image img"><img src="/images/mypage/point/point-list-panel-image.png" alt=""></div>
										<span class="point-list__panel-title">고객님의 적립금 총액</span>
										<span class="point-list__panel-num"><em>{{ me.point }}</em> 원</span>
									</div>
                            	</div>
								<!-- 적립금 내역 -->
								<div class="point-list__body">
									<section class="card-list-container">
										<section class="card-list-fields">
											<span class="field">적립/사용일</span>
											<span class="field">내용</span>
											<span class="field">포인트</span>
										</section>
										<section class="card-list-blocks">
											<div v-for="point in points" class="card-list">
												<div class="card-list__records">
													<div class="record">
														{{ point.createDate.toDate() }}
													</div>
													<div class="record">
														<div class="info">
															{{ point.remark }}
														</div>
													</div>
													<div class="record record--flex">
														<div class="mobile-title">적립금</div>
														<div class="data"><span class="color-red">{{ (0 < point.price) ? '+' + point.price.format() : point.price.format() }} 원</span></div>
													</div>
												</div>
											</div>
										</section>
										<section v-if="more" class="bottom-button">
											<button class="button button--border-primary"><span>더보기</span></button>
										</section>
									</section>
                            	</div>
                            </div>
						</div>
					</div>
				</div>
			</div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from "../../../components/client/inc/main-header.vue";
import MainFooter from "../../../components/client/inc/main-footer.vue";
import MypageNavigation from "../../../components/client/mypage-navigation.vue";

export default{
	components: {
		MainHeader,
		MainFooter,
		MypageNavigation
	},

	data: () => {
		return {
			limit: 10,

			me: null,
			points: [],
			more: true
		};
	},
	created() {
	},
	mounted() {
		this.init();
	},
	methods: {
		async init() {
			try{
				await this.getMyinfo();
				await this.getPoints();
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message.toString());
			}
		},

		getPoints(){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.get('/api/v1/me/points', {
						headers: {
							skip: this.points.length,
							limit: this.limit
						}
					});

					res.data.points.forEach((point) => this.points.push(point));
					this.more = (res.data.summary.totalCount == this.points.length || res.data.points.length == 0) ? false : true;

					resolve();
				}
				catch(error){
					reject(error);
				}
			});
		},

		getMyinfo(){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.get('/api/v1/me/info');

					this.me = res.data.user;

					resolve();
				}
				catch(error){
					reject(error);
				}
			});
		},
	},
}
</script>
