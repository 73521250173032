<template>
	<div class="wrap wrap--mypage">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
			<div class="container">
				<div class="mypage-container">
                    <!-- 배너 타이틀 -->
                    <div class="mypage-title">
                        <h1 class="text">마이페이지</h1>
                    </div>
					<!-- 네비게이션 메뉴 -->
					<nav class="mypage-navigation">
						<mypage-navigation></mypage-navigation>
					</nav>
					<!-- 페이지 콘텐츠 -->
					<div class="mypage-contents">
						<div class="mypage-contents__head">
                            <h2 class="mypage-contents__title">회원탈퇴</h2>
						</div>
						<div class="mypage-contents__body">
                            <div class="leave-main" v-if="leave === 'main'">
								<p>
									회원탈퇴란, 디아이디 사이트에 대한 이용해지를 의미합니다. <br>
									디아이디에서 발송하는 광고성 이메일의 경우 회원탈퇴 요청
									접수 후 24시간 이내에 발송이 중지됩니다.<br>
									다만, 드물게 데이터 처리사정상 일부 지연될 수 있으니 혹 회원탈퇴 요청 후 
									48시간이 지난 후에 광고성 이메일/SMS를 접수하시는 경우 당사에 연락하여 주시기 바랍니다.
								</p>
								<br>
								<p>
									<b>회원탈퇴 시 유의사항</b><br>
									회원탈퇴 시 보유하고 계신 쿠폰 및 적립금은 즉시 소멸되며 동일한 아이디로 재가입 하더라도 복원되지 않습니다.
								</p>
								<br>
								<p>
									<b>탈퇴회원 회원정보 보전기간</b>
									<ul>
										<li>-  회원탈퇴가 완료되더라도 판/구매자의 권익을 보호하기 위해 다음과 같이 회원정보가 일정기간 보존됨을 알려드립니다.</li>
										<li>
											-  관계 법령에 의거하여 보존이 필요한 경우에 한하여 보존됩니다.
											<ol>
												<li>1) 계약 및 청약철회 등에 관한기록 : 5년</li>
												<li>2) 대금결제 및 재화등의 공급에 관한 기록 : 5년</li>
												<li>3) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</li>
												<li>4) 부정이용 등에 관한 기록 : 1년</li>
											</ol>
										</li>
									</ul>
								</p>
                            </div>
							<div class="leave-sub" v-if="leave === 'sub'">
								<div role="table" class="myinfo-table">
									<div role="rowgroup">
										<div role="row">
											<div role="columnheader">탈퇴사유</div>
											<div role="cell">
												<input v-model="withdrawalReason" type="text" class="input input--wide">
											</div>
										</div>
										<div role="row">
											<div role="columnheader">남기실 말씀</div>
											<div role="cell">
												<textarea  v-model="withdrawalMessage"class="textarea textarea--wide" name="name"></textarea>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="bottom-button">
								<button v-if="leave === 'main'" class="button button--border-primary" @click="leave = 'sub'"><span>탈퇴신청</span></button>
								<button v-if="leave === 'sub'" class="button button--border-primary" @click="leave = 'main'"><span>취소</span></button>
								<button v-if="leave === 'sub'" class="button button--primary" @click="withdraw"><span>탈퇴신청</span></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from "../../../components/client/inc/main-header.vue";
import MainFooter from "../../../components/client/inc/main-footer.vue";
import MypageNavigation from "../../../components/client/mypage-navigation.vue";

export default{
	components: {
		MainHeader,
		MainFooter,
		MypageNavigation
	},
	data: function(){
		return{
			leave: 'main',

			withdrawalReason: null,
			withdrawalMessage: null,
		}
	},
	mounted(){
		this.init();
	},
	methods: {
		init(){
			if(!this.accessToken){
				alert("로그인 이후 이용가능합니다");
				this.$router.push("/login");
				return;
			}
		},
		async withdraw(){
			try{
				await this.postWithdraw({
					withdrawalReason: this.withdrawalReason,
					withdrawalMessage: this.withdrawalMessage
				});

				alert("탈퇴처리 되었습니다");

				this.$store.dispatch("logout").then(() => {
					this.$router.push("/");
				});
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message);
			}
		},

		postWithdraw({ withdrawalReason, withdrawalMessage }){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.post("/api/v1/me/withraw", { withdrawalReason, withdrawalMessage });

					resolve();
				}
				catch(error){
					reject(error);
				}
			});
		}
	},
	computed: {
		accessToken(){
			return this.$store.state.accessToken;
		}
	},
	watch: {
		leave() {
			document.querySelector('html').scrollTop = 0;
		}
	}
}
</script>
