<template>
	<div class="wrap wrap--mypage">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
			<div class="container">
				<div class="mypage-container">
                    <!-- 배너 타이틀 -->
                    <div class="mypage-title">
                        <h1 class="text">마이페이지</h1>
                    </div>
					<!-- 네비게이션 메뉴 -->
					<nav class="mypage-navigation">
						<mypage-navigation></mypage-navigation>
					</nav>
					<!-- 페이지 콘텐츠 -->
					<div class="mypage-contents">
						<div class="mypage-contents__head">
                            <h2 class="mypage-contents__title">반려동물 정보</h2>
						</div>
						<div class="mypage-contents__body">
                            <div class="img"><img src="/images/sample/mypage-pet-contents.jpg" alt=""></div>
						</div>
					</div>
				</div>
			</div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from "../../../components/client/inc/main-header.vue";
import MainFooter from "../../../components/client/inc/main-footer.vue";
import MypageNavigation from "../../../components/client/mypage-navigation.vue";

export default{
	components: {
		MainHeader,
		MainFooter,
		MypageNavigation
	},
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>
