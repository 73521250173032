var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.show ? _c('transition', {
    attrs: {
      "name": "modal",
      "appear": ""
    }
  }, [_c('div', {
    staticClass: "modal-container"
  }, [_c('div', {
    staticClass: "modal-wrapper"
  }, [_c('div', {
    staticClass: "modal modal--11"
  }, [_c('div', {
    staticClass: "modal__head"
  }, [_c('h1', {
    staticClass: "modal__title"
  }, [_vm._v("샘플신청상세보기")]), _c('button', {
    staticClass: "modal__close",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  }, [_c('path', {
    attrs: {
      "d": "M0.222,1.636 L1.636,0.221 L15.778,14.363 L14.364,15.778 L0.222,1.636 Z"
    }
  }), _c('path', {
    attrs: {
      "d": "M14.364,0.221 L15.778,1.636 L1.636,15.778 L0.222,14.363 L14.364,0.221 Z"
    }
  })])])]), _c('div', {
    staticClass: "modal__body modal__body--no-padding-top"
  }, [_c('div', {
    staticClass: "order-detail-container"
  }, [_c('div', {
    staticClass: "order-detail__caption"
  }, [_c('ul', {
    staticClass: "order-detail__caption-info"
  }, [_c('li', [_vm._v("신청번호 : " + _vm._s(_vm.order.orderNo))]), _c('li', [_vm._v("신청일자 : " + _vm._s(_vm.order.createDate.toDate()))])])]), _c('section', {
    staticClass: "order-detail__rows"
  }, [_c('section', {
    staticClass: "order-detail__row"
  }, [_c('div', {
    staticClass: "order-detail__block"
  }, [_c('div', {
    staticClass: "order-detail-table",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "order",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("샘플신청번호")]), _c('div', {
    staticClass: "name",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("상품명")]), _c('div', {
    staticClass: "state",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("상태")])])]), _c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, _vm._l(_vm.order.purchases, function (purchase) {
    return _c('div', {
      attrs: {
        "role": "row"
      }
    }, [_c('div', {
      staticClass: "order",
      attrs: {
        "role": "cell"
      }
    }, [_vm._v(_vm._s(purchase.purchaseNo))]), _c('div', {
      staticClass: "name",
      attrs: {
        "role": "cell"
      }
    }, [_vm._v(" " + _vm._s(purchase.product.name) + " "), _c('span', {
      staticClass: "option-lists"
    }, [_vm._v(" " + _vm._s(purchase.name) + " ")])]), _c('div', {
      staticClass: "state",
      attrs: {
        "role": "cell"
      }
    }, [_c('div', {
      staticClass: "mobile-title"
    }, [_vm._v("상태")]), _c('div', {
      staticClass: "data"
    }, [purchase.claimStatus ? _c('span', {
      staticClass: "color-red"
    }, [_vm._v(_vm._s(purchase.claimStatusMessage))]) : _c('span', [_vm._v(_vm._s(purchase.orderStatusMessage))])])])]);
  }), 0)])])]), _c('section', {
    staticClass: "order-detail__row"
  }, [_c('div', {
    staticClass: "order-detail__block"
  }, [_c('div', {
    staticClass: "order-detail-right sp"
  }, [_c('a', {
    staticClass: "button button--border",
    attrs: {
      "href": "",
      "onclick": "return false;"
    }
  }, [_vm._v("배송지 정보 변경")])]), _c('div', {
    staticClass: "order-detail-table",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "info",
    attrs: {
      "role": "columnheader"
    }
  }, [_c('b', [_vm._v("배송지 정보")])]), _c('div', {
    staticClass: "data",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm.editable && _vm.mode == 'view' ? _c('button', {
    staticClass: "button button--border",
    on: {
      "click": _vm.edit
    }
  }, [_vm._v("변경")]) : _vm._e(), _vm.editable && _vm.mode == 'edit' ? _c('button', {
    staticClass: "button button--border",
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")]) : _vm._e()])])]), _c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "info",
    attrs: {
      "role": "cell"
    }
  }, [_c('b', [_vm._v("이름")])]), _c('div', {
    staticClass: "data",
    attrs: {
      "role": "cell"
    }
  }, [_vm._v(_vm._s(_vm.order.receiver.name))])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "info",
    attrs: {
      "role": "cell"
    }
  }, [_c('b', [_vm._v("주소")])]), _vm.mode == 'view' ? _c('div', {
    staticClass: "data",
    attrs: {
      "role": "cell"
    }
  }, [_vm._v(" [" + _vm._s(_vm.order.receiver.postcode) + "] " + _vm._s(_vm.order.receiver.address1) + " " + _vm._s(_vm.order.receiver.address2) + " ")]) : _c('div', {
    staticClass: "data",
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "form-address"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.order.receiver.postcode,
      expression: "order.receiver.postcode"
    }],
    staticClass: "input",
    attrs: {
      "placeholder": "우편번호",
      "readonly": ""
    },
    domProps: {
      "value": _vm.order.receiver.postcode
    },
    on: {
      "click": _vm.searchPostcode,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.order.receiver, "postcode", $event.target.value);
      }
    }
  }), _c('button', {
    staticClass: "button button--border-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.searchPostcode
    }
  }, [_c('span', [_vm._v("주소찾기")])])]), _c('div', {
    staticClass: "row"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.order.receiver.address1,
      expression: "order.receiver.address1"
    }],
    staticClass: "input",
    attrs: {
      "placeholder": "기본주소",
      "readonly": ""
    },
    domProps: {
      "value": _vm.order.receiver.address1
    },
    on: {
      "click": _vm.searchPostcode,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.order.receiver, "address1", $event.target.value);
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.order.receiver.address2,
      expression: "order.receiver.address2"
    }],
    staticClass: "input",
    attrs: {
      "placeholder": "상세주소"
    },
    domProps: {
      "value": _vm.order.receiver.address2
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.order.receiver, "address2", $event.target.value);
      }
    }
  })])])])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "info",
    attrs: {
      "role": "cell"
    }
  }, [_c('b', [_vm._v("연락처")])]), _c('div', {
    staticClass: "data",
    attrs: {
      "role": "cell"
    }
  }, [_vm._v(_vm._s(_vm.order.receiver.phone))])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "info",
    attrs: {
      "role": "cell"
    }
  }, [_c('b', [_vm._v("요청사항")])]), _c('div', {
    staticClass: "data",
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "flexbox"
  }, [_vm.mode == 'view' ? _c('span', [_vm._v(_vm._s(_vm.order.requestMessage))]) : _vm._e(), _vm.mode == 'edit' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.order.requestMessage,
      expression: "order.requestMessage"
    }],
    domProps: {
      "value": _vm.order.requestMessage
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.order, "requestMessage", $event.target.value);
      }
    }
  }) : _vm._e(), _vm.editable && _vm.mode == 'view' ? _c('button', {
    staticClass: "button button--border-solid",
    on: {
      "click": _vm.edit
    }
  }, [_vm._v("변경")]) : _vm._e(), _vm.editable && _vm.mode == 'edit' ? _c('button', {
    staticClass: "button button--border-solid",
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")]) : _vm._e()])])])])])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "order-detail__block"
  }, [_c('div', {
    staticClass: "order-detail-table",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "info",
    attrs: {
      "role": "columnheader"
    }
  }, [_c('b', [_vm._v("결제정보")])]), _c('div', {
    staticClass: "data",
    attrs: {
      "role": "columnheader"
    }
  })])]), _c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "info",
    attrs: {
      "role": "cell"
    }
  }, [_c('b', [_vm._v("최종결제금액")])]), _c('div', {
    staticClass: "data",
    attrs: {
      "role": "cell"
    }
  }, [_vm._v(_vm._s(_vm.order.totalPrice.format()) + " 원")])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "info",
    attrs: {
      "role": "cell"
    }
  }, [_c('b', [_vm._v("결제 방법")])]), _c('div', {
    staticClass: "data",
    attrs: {
      "role": "cell"
    }
  }, [_vm.order.paymentMethod == 'card' ? _c('span', [_vm._v("신용카드")]) : _vm._e(), _vm.order.paymentMethod == 'account' ? _c('span', [_vm._v("무통장입금")]) : _vm._e(), _vm.order.paymentMethod == 'kakao' ? _c('span', [_vm._v("카카오페이")]) : _vm._e()])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "info",
    attrs: {
      "role": "cell"
    }
  }, [_c('b', [_vm._v("상품금액")])]), _c('div', {
    staticClass: "data",
    attrs: {
      "role": "cell"
    }
  }, [_vm._v(_vm._s(_vm.order.productPrice.format()) + " 원")])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "info",
    attrs: {
      "role": "cell"
    }
  }, [_c('b', [_vm._v("할인금액(합산)")])]), _c('div', {
    staticClass: "data",
    attrs: {
      "role": "cell"
    }
  }, [_vm._v("- " + _vm._s(_vm.order.discountPrice.format()) + "원")])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "info",
    attrs: {
      "role": "cell"
    }
  }, [_c('b', [_vm._v("배송비")])]), _c('div', {
    staticClass: "data",
    attrs: {
      "role": "cell"
    }
  }, [_vm._v(_vm._s(_vm.order.deliveryPrice.format()) + "원")])])])])])])])])]), _c('div', {
    staticClass: "modal__foot"
  }, [_c('div', {
    staticClass: "modal__buttons"
  }, [_c('button', {
    staticClass: "button button--primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('span', [_vm._v("확인")])])])])])]), _c('daum-postcode', {
    ref: "daum-postcode",
    on: {
      "input": _vm.changePostcode
    }
  })], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }