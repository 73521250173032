var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--mypage"
  }, [_c('main-header'), _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "mypage-container"
  }, [_vm._m(0), _c('nav', {
    staticClass: "mypage-navigation"
  }, [_c('mypage-navigation')], 1), _c('div', {
    staticClass: "mypage-contents"
  }, [_vm._m(1), _c('div', {
    staticClass: "mypage-contents__body"
  }, [_c('div', {
    staticClass: "point-list-container"
  }, [_c('div', {
    staticClass: "point-list__head"
  }, [_c('div', {
    staticClass: "point-list__panel"
  }, [_vm._m(2), _c('span', {
    staticClass: "point-list__panel-title"
  }, [_vm._v("고객님의 적립금 총액")]), _c('span', {
    staticClass: "point-list__panel-num"
  }, [_c('em', [_vm._v(_vm._s(_vm.me.point))]), _vm._v(" 원")])])]), _c('div', {
    staticClass: "point-list__body"
  }, [_c('section', {
    staticClass: "card-list-container"
  }, [_vm._m(3), _c('section', {
    staticClass: "card-list-blocks"
  }, _vm._l(_vm.points, function (point) {
    return _c('div', {
      staticClass: "card-list"
    }, [_c('div', {
      staticClass: "card-list__records"
    }, [_c('div', {
      staticClass: "record"
    }, [_vm._v(" " + _vm._s(point.createDate.toDate()) + " ")]), _c('div', {
      staticClass: "record"
    }, [_c('div', {
      staticClass: "info"
    }, [_vm._v(" " + _vm._s(point.remark) + " ")])]), _c('div', {
      staticClass: "record record--flex"
    }, [_c('div', {
      staticClass: "mobile-title"
    }, [_vm._v("적립금")]), _c('div', {
      staticClass: "data"
    }, [_c('span', {
      staticClass: "color-red"
    }, [_vm._v(_vm._s(0 < point.price ? '+' + point.price.format() : point.price.format()) + " 원")])])])])]);
  }), 0), _vm.more ? _c('section', {
    staticClass: "bottom-button"
  }, [_vm._m(4)]) : _vm._e()])])])])])])])]), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mypage-title"
  }, [_c('h1', {
    staticClass: "text"
  }, [_vm._v("마이페이지")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mypage-contents__head"
  }, [_c('h2', {
    staticClass: "mypage-contents__title"
  }, [_vm._v("적립금")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "point-list__panel-image img"
  }, [_c('img', {
    attrs: {
      "src": "/images/mypage/point/point-list-panel-image.png",
      "alt": ""
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "card-list-fields"
  }, [_c('span', {
    staticClass: "field"
  }, [_vm._v("적립/사용일")]), _c('span', {
    staticClass: "field"
  }, [_vm._v("내용")]), _c('span', {
    staticClass: "field"
  }, [_vm._v("포인트")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "button button--border-primary"
  }, [_c('span', [_vm._v("더보기")])]);

}]

export { render, staticRenderFns }