<template id="modal-template">
    <transition v-if="show" name="modal" appear>
        <div class="modal-container">
            <div class="modal-wrapper">
                <div class="modal">
                    <div class="modal__head">
                        <h1 class="modal__title">구매후기등록</h1>
                        <button class="modal__close" type="button" @click="close">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <path d="M0.222,1.636 L1.636,0.221 L15.778,14.363 L14.364,15.778 L0.222,1.636 Z"/>
                                <path d="M14.364,0.221 L15.778,1.636 L1.636,15.778 L0.222,14.363 L14.364,0.221 Z"/>
                            </svg>
                        </button>
                    </div>
                    <div class="modal__body">
                        <div class="modal-review-container">
                            <h2 class="modal-review__name">
                                <div>{{ purchase.product.name }}</div>
                                <div>{{ purchase.name }}</div>
                            ​</h2>
                            <div class="modal-review__evaluation">
                                <div class="star-rating-container">
                                    <span v-for="star in review.star" class="star-rating" role="button" @click="review.star = star">
                                        <svg class="star" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 488.2">
                                            <linearGradient id="starOrder1" x1="0" x2="100%" y1="0" y2="0">
                                                <stop offset="100%" stop-color="#ffdc64"></stop>
                                                <stop offset="100%" stop-color="#dddddd"></stop>
                                            </linearGradient>
                                            <path d="M499.9,176.4L334.1,161L268.2,8c-4.6-10.7-19.8-10.7-24.4,0l-65.9,153L12.1,176.4c-11.6,1.1-16.3,15.5-7.5,23.2l125.1,109.9L93,472c-2.6,11.4,9.7,20.3,19.7,14.3l143.2-85l143.2,85c10,6,22.3-3,19.8-14.3l-36.6-162.5l125.1-109.9C516.2,191.9,511.5,177.5,499.9,176.4" fill="url('#starOrder1')"/>
                                        </svg>
                                    </span>
                                    <span v-for="star in 5 - review.star" class="star-rating" role="button" @click="review.star = review.star + star">
                                        <svg class="star" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 488.2">
                                            <linearGradient id="starOrder5" x1="0" x2="100%" y1="0" y2="0">
                                                <stop offset="0%" stop-color="#ffdc64"></stop>
                                                <stop offset="0%" stop-color="#dddddd"></stop>
                                            </linearGradient>
                                            <path d="M499.9,176.4L334.1,161L268.2,8c-4.6-10.7-19.8-10.7-24.4,0l-65.9,153L12.1,176.4c-11.6,1.1-16.3,15.5-7.5,23.2l125.1,109.9L93,472c-2.6,11.4,9.7,20.3,19.7,14.3l143.2-85l143.2,85c10,6,22.3-3,19.8-14.3l-36.6-162.5l125.1-109.9C516.2,191.9,511.5,177.5,499.9,176.4" fill="url('#starOrder5')"/>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div class="modal-review__text">
                                <textarea v-model="review.content" class="textarea" name="name" maxlength="250" placeholder="구매후기를 적어 주세요.(250자내)​"></textarea>
                            </div>
                            <div class="modal-review__upload">
                                <div class="modal-review__upload-left">
                                    <span class="modal-review__upload-title">
                                        후기 이미지 등록 <small>상품 이미지는 3개까지만 등록가능합니다.</small>
                                    </span>
                                </div>
                                <div class="modal-review__upload-right">
                                    <label class="modal-review__upload-image">
                                        <input ref="image1" class="input" type="file" @input="$forceUpdate()">
                                        <span class="thumbnail" :style="{backgroundImage: $refs.image1 ? createObjectURL($refs.image1.files[0]) : ''}"></span>
                                    </label>
                                    <label class="modal-review__upload-image">
                                        <input ref="image2" class="input" type="file" @input="$forceUpdate()">
                                        <span class="thumbnail" :style="{backgroundImage: $refs.image2 ? createObjectURL($refs.image2.files[0]) : ''}"></span>
                                    </label>
                                    <label class="modal-review__upload-image">
                                        <input ref="image3" class="input" type="file" @input="$forceUpdate()">
                                        <span class="thumbnail" :style="{backgroundImage: $refs.image3 ? createObjectURL($refs.image3.files[0]) : ''}"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal__foot modal__foot--border">
                        <div class="modal__buttons">
                            <button class="button button--primary" type="button" @click="save"><span>등록하기</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default{
    data: () => {
        return {
            show: false,
            purchase: null,
            review: {
                star: 1
            }
        };
    },
    methods: {
        open(){
            this.show = true;
            document.querySelector('html').setAttribute('data-modal', 'opened');
        },
        close(){
            this.show = false;
            document.querySelector('html').removeAttribute('data-modal');
        },

        async save(){
            try{
                alert(this.purchase._id);
                var review = await this.postReview(Object.assign({
                    _purchase: this.purchase._id,
                    _product: this.purchase._product
                }, this.review));
                if(this.$refs.image1.files[0]) await this.postImage({ _review: review._id, file: this.$refs.image1.files[0], index: 0});
                if(this.$refs.image2.files[0]) await this.postImage({ _review: review._id, file: this.$refs.image2.files[0], index: 1});
                if(this.$refs.image3.files[0]) await this.postImage({ _review: review._id, file: this.$refs.image3.files[0], index: 2});

                this.purchase._review = review._id;
                this.close();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        postReview(review){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.post('/api/v1/me/reviews', review);

                    resolve(res.data.review);
                }
                catch(error){
                    reject(error);
                }
            });
        },

        postImage({ _review, file, index}){
            return new Promise(async (resolve, reject) => {
                try{
                    var formData = new FormData();
                    formData.append("image", file);

                    var res = await this.$http.post(`/api/v1/me/reviews/${_review}/images/${index}`, formData, {
                        headers: { "Content-Type": "multipart/form-data" }
                    });

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        createObjectURL(file){
            if(!file) return;
            return `url('${URL.createObjectURL(file)}')`;
        }
    }
}
</script>
