<template>
	<div class="wrap wrap--products">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
            <div class="container">
                <div class="mypage-container">
                    <!-- 배너 타이틀 -->
                    <div class="mypage-title">
                        <h1 class="text">마이페이지</h1>
                    </div>
					<!-- 네비게이션 메뉴 -->
					<nav class="mypage-navigation">
						<mypage-navigation></mypage-navigation>
					</nav>
					<!-- 페이지 콘텐츠 -->
					<div class="mypage-contents">
						<div class="mypage-contents__head">
                            <h2 class="mypage-contents__title">찜한목록</h2>
						</div>
                        <div class="mypage-contents__body">
                            <div class="product-cart-container">
                                <!-- 페이지 타이틀 -->
                                <!-- <div class="order-title">
                                    <h1 class="order-title__text">장바구니</h1>
                                    <h1 class="order-title__text">찜한 목록</h1>
                                </div> -->
                                <!-- 장바구니 -->
                                <div class="cart-list-container">
                                    <!-- 장바구니 체크박스 제어 버튼 -->
                                    <div class="cart-list-group">
                                        <ul class="cart-list-group__lists">
                                            <li @click="selected.length == cartsMap.length ? selected = [] : selected = cartsMap.map((map) => map)"><a>전체선택</a></li>
                                            <li @click="remove"><a>선택삭제</a></li>
                                        </ul>
                                    </div>
                                    <!-- 장바구니 리스트 테이블 -->
                                    <div class="cart-list-table">
                                        <div class="tr">
                                            <div class="th">
                                                <!-- 체크박스 -->
                                                <!-- <label class="check"><input class="input" type="checkbox" :checked="pickup.length == cartsMap.length" @click="pickup.length == cartsMap.length ? pickup = [] : pickup = cartsMap.map((map) => map)"><i class="icon"></i></label> -->
                                                상품정보
                                            </div>
                                        </div>
                                        <div v-for="(map, index) in cartsMap" class="tr">
                                            <div class="td">
                                                <div class="basket">
                                                    <!-- 체크박스 -->
                                                    <div class="basket__check">
                                                        <label class="check"><input v-model="selected" class="input" type="checkbox" name="selected" :value="map"><i class="icon"></i></label>
                                                    </div>
                                                    <!-- 상품박스 -->
                                                    <div class="basket__box">
                                                        <div class="basket__thumb">
                                                            <a href="#" @click="$router.push(`/collection/${map.product.paperCollection.code}?code=${map.product.code}`)">
                                                                <img height="160px" width="160px" :src="map.product.thumb" alt="">
                                                            </a>
                                                            <!-- <a class="basket__thumb-link" href="#" @click="$router.push(`/collection/${map.product.paperCollection.code}?code=${map.product.code}`)">
                                                                <img class="basket__thumbnail" :src="map.product.thumb" alt="">
                                                            </a> -->
                                                        </div>
                                                        <div class="basket__info">
                                                            <span class="basket__title"><a class="link" href="#" @click="$router.push(`/collection/${map.product.paperCollection.code}?code=${map.product.code}`)">[{{ map.product.code}}] {{ map.product.name }}​</a></span>												<!-- 상품 옵션 리스트 -->
                                                            <div>
                                                                <span>[{{ map.product.paperCollection.type }}]</span>
                                                            </div>
															<div v-if="!map.product.paperCollection.isSample">
                                                                <span style="color: red;">샘플 신청이 불가능한 제품입니다.</span>
                                                            </div>
                                                            <!-- 추가정보 필요 시 사용하세요.
                                                            <span class="basket__text">[제품코드]</span> -->
                                                            <div v-show="false" class="basket__option">
                                                                <div v-for="(cart, index) in map.carts" class="basket__option-list">
                                                                    <span class="basket__option-title">{{ cart.name }}</span>
                                                                    <div class="basket__option-bottom">
                                                                        <!-- 옵션 수량 -->
                                                                        <div class="spinner">
                                                                            <button class="spinner__minus" type="button" @click="decAmount(cart)"></button>
                                                                            <input v-model="cart.amount" class="spinner__num" type="number" @change="changeAmount(cart)">
                                                                            <button class="spinner__plus" type="button" @click="incAmount(cart)"></button>
                                                                        </div>
                                                                        <div class="basket__option-price">
                                                                            <span class="price">
                                                                                <em class="num">{{ (cart.salePrice * cart.amount).format() }}원</em>
                                                                            </span>
                                                                        </div>
                                                                        <!-- <button class="basket__option-delete" type="button"><span class="blind">옵션삭제</span></button> -->
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- 페이지 버튼 -->
                                <div class="order-buttons">
                                    <a class="button button--border-primary" href="#" @click="$router.go(-1)"><span>이전페이지</span></a>
                                    <!-- <a class="button button--primary" href="#" @click="order"><span>선택 주문하기</span></a> -->
                                    <a class="button button--primary" href="#" @click="order"><span>샘플 신청하기</span></a>
                                    <naver-pay button-key="E8FAC592-0582-4E64-B0A7-410385FFD5AA" :carts="selected.reduce((carts, map) => { return carts.concat(map.carts); }, [])" count="1"></naver-pay>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from "@/components/client/inc/main-header.vue";
import MainFooter from "@/components/client/inc/main-footer.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";

import NaverPay from "@/components/plugins/naver/naver-pay.vue";

export default{
	components: {
		MainHeader,
		MainFooter,
		MypageNavigation,
		NaverPay
	},
	data: () => {
		return {
			carts: [],
			selected: []
		}
	},
	mounted() {
		this.init();
	},
	methods : {
		async init() {
			try{
				this.carts = await this.getCarts();
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
			}
		},

		order(){
			try{
                if(this.selected.find(cart => cart.product.paperCollection.type == "수출용" )){
                    throw new Error("수출북 샘플신청은 지원되지 않습니다.\n논현동/부산 디아이디 패밀리샵에 오시면 100권 이상의 수출 샘플북을 직접 확인하고 구매 하실 수 있습니다.");
                }

				var carts = this.selected.reduce((carts, map) => { return carts.concat(map.carts); }, []);

				if(!carts.length) throw new Error("구매하실 상품을 선택해주세요");

				if (this.selected.find(cart => cart.product.paperCollection.isSample == false )) {
					throw new Error("샘플 신청이 불가능한 제품이 포함되어 있습니다.\n 선택 해제 후 신청 바랍니다.");
				}

				this.$store.dispatch("pickup", carts.map((cart) => { return cart._id; }));
				this.$router.push("/shop/order");
			}
			catch(error){
				alert(error.message);
			}
		},

		async remove(){
			try{
				var carts = this.selected.reduce((carts, map) => { return carts.concat(map.carts); }, []);
				for(var i in carts){
					await this.deleteCart(carts[i]);
				}

				this.carts = await this.getCarts();
			}
			catch(error){
				console.error(error);
				if(error.repsonse) alert(error.response.data.message);
				else alert(error.message.toString());
			}
		},

		incAmount(cart){
			if(cart.amount < cart.stock) cart.amount++;
		},

		decAmount(cart){
			if(1 < cart.amount) cart.amount--;
		},

		changeAmount(cart){
			if(cart.amount < 1) cart.amount = 1;
			if(cart.stock < cart.amount) cart.amount = cart.stock;
		},

		getCarts(){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.get('/api/v1/me/carts', {
						params: {
							_carts: this.accessToken ? [] : this.$store.state.carts.map((cart) => { return cart._id; })
						}
					});

					resolve(res.data.carts);
				}
				catch(error){
					reject(error);
				}
			});
		},

		deleteCart(cart){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.delete(`/api/v1/me/carts/${cart._id}`);

					resolve();
				}
				catch(error){
					reject(error);
				}
			});
		}
	},
	computed: {
		accessToken(){
			return this.$store.state.accessToken;
		},
		payload(){
			return this.$store.state.payload;
		},
		cartsMap(){
			var map = new Map();
			for(var i in this.carts){
				var cart = this.carts[i];
				var value = map.get(cart._product);
				if(!cart.product) continue;
				if(value) value.carts.push(cart);
				else value = { product: cart.product, carts: [cart] };
				map.set(cart._product, value);
			}
			return Array.from(map.values());
		}
	}
}
</script>
