var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": `naver-pay-button-${_vm.uuid}`,
      "oncontextmenu": "return false;"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }