var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "modal",
      "appear": ""
    }
  }, [_c('div', {
    staticClass: "modal-container"
  }, [_c('div', {
    staticClass: "modal-wrapper"
  }, [_c('div', {
    staticClass: "modal modal--6"
  }, [_c('button', {
    staticClass: "modal__close",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  }, [_c('path', {
    attrs: {
      "d": "M0.222,1.636 L1.636,0.221 L15.778,14.363 L14.364,15.778 L0.222,1.636 Z"
    }
  }), _c('path', {
    attrs: {
      "d": "M14.364,0.221 L15.778,1.636 L1.636,15.778 L0.222,14.363 L14.364,0.221 Z"
    }
  })])]), _c('div', {
    staticClass: "modal__body modal__body--top"
  }, [_c('div', {
    staticClass: "modal-message"
  }, [_c('span', {
    staticClass: "modal-message__icon"
  }, [_c('i', {
    staticClass: "icon icon-order-delete"
  })]), _c('p', {
    staticClass: "modal-message__pr"
  }, [_vm._v(" 주문내역을 삭제하시겠습니까?"), _c('br'), _c('small', [_vm._v("삭제 시 복구가 불가능 합니다.")])])])]), _c('div', {
    staticClass: "modal__foot modal__foot-no-border"
  }, [_c('div', {
    staticClass: "modal__buttons modal__buttons--narrow"
  }, [_c('a', {
    staticClass: "button button--border-primary",
    attrs: {
      "href": "",
      "onclick": "return false;"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('span', [_vm._v("취소")])]), _c('a', {
    staticClass: "button button--primary",
    attrs: {
      "href": "",
      "onclick": "return false;"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('span', [_vm._v("삭제하기")])])])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }