<template id="modal-template">
    <transition v-if="show && purchase" name="modal" appear>
        <div class="modal-container">
            <div class="modal-wrapper">
                <div class="modal">
                    <div class="modal__head">
                        <h1 class="modal__title">
                            <span v-if="cancelEnabled">취소요청</span>
                            <span v-if="returnEnabled">반품요청</span>
                            <span v-if="exchangeEnabled">교환요청</span>
                        </h1>
                        <button class="modal__close" type="button" @click="close">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <path d="M0.222,1.636 L1.636,0.221 L15.778,14.363 L14.364,15.778 L0.222,1.636 Z"/>
                                <path d="M14.364,0.221 L15.778,1.636 L1.636,15.778 L0.222,14.363 L14.364,0.221 Z"/>
                            </svg>
                        </button>
                    </div>
                    <div class="modal__body">
                        <div class="modal-inquiry-container">
                            <div class="modal-inquiry">
                                <h2 class="modal-inquiry__name"></h2>
                                <form class="modal-inquiry__form">
                                    <div class="modal-inquiry__form-row">
                                        <select v-model="claimReason" class="select">
                                            <option :value="null" disabled selected>요청사유를 선택하세요.</option>
                                            <option v-for="reason in claimReasons" :value="reason">{{ reason }}</option>
                                        </select>
                                    </div>
                                    <div class="modal-inquiry__form-row">
                                        <input  type="hidden">
                                        <textarea v-model="claimReasonDetails" class="textarea" name="name" placeholder="요청사유를 입력해주세요."></textarea>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal__foot modal__foot--border">
                        <div class="modal__buttons">
                            <button class="button button--primary" type="button">
                                <span v-if="cancelEnabled"  @click="cancelRequest">취소요청</span>
                                <span v-if="exchangeEnabled" @click="exchangeRequest">교환요청</span>
                                <span v-if="returnEnabled" @click="returnRequest">반품요청</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default{
    data: () => {
        return {
            show: false,

            purchase: null,

            claimReason: null,
            claimReasonDetails: null,

            exchangeReasons: ["단순 변심에 의한 교환"],
            returnReasons: ["단순 변심에 의한 반품", "상품 불량에 의한 반품", "상품 오배송에 의한 반품", "상품 미도착에 의한 반품", "기타"],
            cancelReasons: ["단순 변심에 의한 주문취소", "기타"]
        }
    },
    methods: {
        open(){
            this.show = true;
            document.querySelector('html').setAttribute('data-modal', 'opened');
        },
        close(){
            this.show = false;
            document.querySelector('html').removeAttribute('data-modal');
        },

        //////////////////////////////////////////////////////////////
        // 반품요청
        //////////////////////////////////////////////////////////////
        async returnRequest(){
            try{
                Object.assign(this.purchase, await this.putPurchase({
                    _id: this.purchase._id,
                    claimStatus: "return-request",
                    claimReason: this.claimReason,
                    claimReasonDetails: this.claimReasonDetails
                }));

                this.close();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },

        //////////////////////////////////////////////////////////////
		// 교환요청
        //////////////////////////////////////////////////////////////
		async exchangeRequest(){
			try{
				Object.assign(this.purchase, await this.putPurchase({
                    _id: this.purchase._id,
                    claimStatus: "exchange-request",
                    claimReason: this.claimReason,
                    claimReasonDetails: this.claimReasonDetails
                }));

                this.close();
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
			}
		},

        //////////////////////////////////////////////////////////////
		// 취소요청
        //////////////////////////////////////////////////////////////
		async cancelRequest(){
			try{
				Object.assign(this.purchase, await this.putPurchase({
                    _id: this.purchase._id,
                    claimStatus: "cancel-request",
                    claimReason: this.claimReason,
                    claimReasonDetails: this.claimReasonDetails
                }));

                this.close();
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
			}
		},

        //////////////////////////////////////////////////////////////
        // 구매내역 수정
        //////////////////////////////////////////////////////////////
        putPurchase(purchase){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.put(`/api/v1/me/purchases/${purchase._id}`, purchase);

                    resolve(res.data.purchase);
                }
                catch(error){
                    reject(error);
                }
            });
        },
    },
    computed: {
        claimReasons(){
            return [
                "단순 변심",
                "상품 불량",
                "기타"
            ];
            // if(!this.purchase) return [];
            // else if(this.returnEnabled) return [];
            // else if(this.exchangeEnabled) return [];
            // else if(this.cancelEnabled) return [];
        },

        //////////////////////////////////////////////////////////////
        // 반품가능여부
        //////////////////////////////////////////////////////////////
        returnEnabled(){
            if(this.purchase.claimStatus) return false;
            switch(this.purchase.orderStatus){
                case "shipping-confirm":
                case "shipping-delay":
                case "shipping-cancel":
                case "delivery-complete": {
                    return true;
                }
            }
            return false;
        },

        //////////////////////////////////////////////////////////////
        // 교환가능여부
        //////////////////////////////////////////////////////////////
        exchangeEnabled(){
            if(this.purchase.claimStatus) return false;
            switch(this.purchase.orderStatus){
                case "shipping-confirm":
                case "shipping-delay":
                case "shipping-cancel":
                case "delivery-complete": {
                    return true;
                }
            }
            return false;
        },

        //////////////////////////////////////////////////////////////
        // 취소가능여부
        //////////////////////////////////////////////////////////////
        cancelEnabled(){
            if(this.purchase.claimStatus) return false;
            switch(this.purchase.orderStatus){
                case "order-nonpayment":
                case "order-payment":
                case "order-confirm": {
                    return true;
                }
            }
            return false;
        },
    }
}
</script>
