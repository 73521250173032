var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--mypage"
  }, [_c('main-header'), _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "mypage-container"
  }, [_vm._m(0), _c('nav', {
    staticClass: "mypage-navigation"
  }, [_c('mypage-navigation')], 1), _c('div', {
    staticClass: "mypage-contents"
  }, [_vm._m(1), _c('div', {
    staticClass: "mypage-contents__body"
  }, [_vm.leave === 'main' ? _c('div', {
    staticClass: "leave-main"
  }, [_vm._m(2), _c('br'), _vm._m(3), _c('br'), _vm._m(4)]) : _vm._e(), _vm.leave === 'sub' ? _c('div', {
    staticClass: "leave-sub"
  }, [_c('div', {
    staticClass: "myinfo-table",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("탈퇴사유")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.withdrawalReason,
      expression: "withdrawalReason"
    }],
    staticClass: "input input--wide",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.withdrawalReason
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.withdrawalReason = $event.target.value;
      }
    }
  })])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("남기실 말씀")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.withdrawalMessage,
      expression: "withdrawalMessage"
    }],
    staticClass: "textarea textarea--wide",
    attrs: {
      "name": "name"
    },
    domProps: {
      "value": _vm.withdrawalMessage
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.withdrawalMessage = $event.target.value;
      }
    }
  })])])])])]) : _vm._e(), _c('div', {
    staticClass: "bottom-button"
  }, [_vm.leave === 'main' ? _c('button', {
    staticClass: "button button--border-primary",
    on: {
      "click": function ($event) {
        _vm.leave = 'sub';
      }
    }
  }, [_c('span', [_vm._v("탈퇴신청")])]) : _vm._e(), _vm.leave === 'sub' ? _c('button', {
    staticClass: "button button--border-primary",
    on: {
      "click": function ($event) {
        _vm.leave = 'main';
      }
    }
  }, [_c('span', [_vm._v("취소")])]) : _vm._e(), _vm.leave === 'sub' ? _c('button', {
    staticClass: "button button--primary",
    on: {
      "click": _vm.withdraw
    }
  }, [_c('span', [_vm._v("탈퇴신청")])]) : _vm._e()])])])])])]), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mypage-title"
  }, [_c('h1', {
    staticClass: "text"
  }, [_vm._v("마이페이지")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mypage-contents__head"
  }, [_c('h2', {
    staticClass: "mypage-contents__title"
  }, [_vm._v("회원탈퇴")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" 회원탈퇴란, 디아이디 사이트에 대한 이용해지를 의미합니다. "), _c('br'), _vm._v(" 디아이디에서 발송하는 광고성 이메일의 경우 회원탈퇴 요청 접수 후 24시간 이내에 발송이 중지됩니다."), _c('br'), _vm._v(" 다만, 드물게 데이터 처리사정상 일부 지연될 수 있으니 혹 회원탈퇴 요청 후  48시간이 지난 후에 광고성 이메일/SMS를 접수하시는 경우 당사에 연락하여 주시기 바랍니다. ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('b', [_vm._v("회원탈퇴 시 유의사항")]), _c('br'), _vm._v(" 회원탈퇴 시 보유하고 계신 쿠폰 및 적립금은 즉시 소멸되며 동일한 아이디로 재가입 하더라도 복원되지 않습니다. ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('b', [_vm._v("탈퇴회원 회원정보 보전기간")]), _c('ul', [_c('li', [_vm._v("- 회원탈퇴가 완료되더라도 판/구매자의 권익을 보호하기 위해 다음과 같이 회원정보가 일정기간 보존됨을 알려드립니다.")]), _c('li', [_vm._v(" - 관계 법령에 의거하여 보존이 필요한 경우에 한하여 보존됩니다. "), _c('ol', [_c('li', [_vm._v("1) 계약 및 청약철회 등에 관한기록 : 5년")]), _c('li', [_vm._v("2) 대금결제 및 재화등의 공급에 관한 기록 : 5년")]), _c('li', [_vm._v("3) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년")]), _c('li', [_vm._v("4) 부정이용 등에 관한 기록 : 1년")])])])])]);

}]

export { render, staticRenderFns }