var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-search"
  }, [_c('div', {
    staticClass: "board-search__box"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filter.searchValue,
      expression: "filter.searchValue"
    }],
    staticClass: "board-search__input",
    attrs: {
      "type": "text",
      "name": "",
      "value": ""
    },
    domProps: {
      "value": _vm.filter.searchValue
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.filter, "searchValue", $event.target.value);
      }
    }
  }), _c('button', {
    staticClass: "board-search__button",
    attrs: {
      "type": "submit",
      "name": "button"
    }
  })]), _c('div', {
    staticClass: "board-search__select"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filter.type,
      expression: "filter.type"
    }],
    staticClass: "board-search__selectbox",
    attrs: {
      "name": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.filter, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "",
      "disabled": "",
      "selected": ""
    }
  }, [_vm._v("문의항목을 선택해주세요.")])])])]), _c('div', {
    staticClass: "board-table board-table--inquiry",
    attrs: {
      "role": "table"
    }
  }, [_vm._m(0), _vm._l(_vm.inquires, function (inquire, index) {
    return _c('div', [_c('div', {
      attrs: {
        "role": "rowgroup"
      }
    }, [_c('div', {
      attrs: {
        "role": "row"
      }
    }, [_c('div', {
      staticClass: "num",
      attrs: {
        "role": "cell"
      }
    }, [_vm._v(_vm._s((_vm.page - 1) * _vm.limit + index + 1))]), _c('div', {
      staticClass: "title",
      attrs: {
        "role": "cell"
      }
    }, [_c('div', {
      staticClass: "title"
    }, [_c('a', {
      attrs: {
        "role": "button",
        "data-idx": "5"
      }
    }, [_vm._v(_vm._s(inquire.subject))])])]), _c('div', {
      staticClass: "cell type",
      attrs: {
        "role": "cell"
      }
    }, [_vm._v(_vm._s(inquire.type))]), _c('div', {
      staticClass: "cell state",
      attrs: {
        "role": "cell"
      }
    }, [_vm._v(_vm._s(inquire.reply ? "답변완료" : "미답변"))]), _c('div', {
      staticClass: "cell date",
      attrs: {
        "role": "cell"
      }
    }, [_c('span', {
      staticClass: "date"
    }, [_vm._v(_vm._s(inquire.createDate.toDate()))])])])]), _c('div', {
      staticClass: "answer",
      attrs: {
        "role": "rowgroup",
        "data-idx-answer": "5"
      }
    }, [_c('div', {
      attrs: {
        "role": "row"
      }
    }, [_c('div', {
      staticClass: "num",
      attrs: {
        "role": "cell"
      }
    }), _c('div', {
      staticClass: "question",
      attrs: {
        "role": "cell"
      }
    }, [_c('div', {
      staticClass: "question",
      domProps: {
        "innerHTML": _vm._s(inquire.content.replace(/\n/g, '<br>'))
      }
    })])]), inquire.reply ? _c('div', {
      staticClass: "background",
      attrs: {
        "role": "row"
      }
    }, [_vm._m(1, true), _c('div', {
      staticClass: "answer",
      attrs: {
        "role": "cell"
      }
    }, [_c('div', {
      staticClass: "answer",
      domProps: {
        "innerHTML": _vm._s(inquire.reply.replace(/\n/g, '<br>'))
      }
    })])]) : _vm._e()])]);
  })], 2), _c('pagination-component', {
    attrs: {
      "count": _vm.pageCount
    },
    on: {
      "input": _vm.getInquires
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "num",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("번호")]), _c('div', {
    staticClass: "title",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("문의사항")]), _c('div', {
    staticClass: "cell",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("문의유형")]), _c('div', {
    staticClass: "cell",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("답변상태")]), _c('div', {
    staticClass: "cell",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("작성일")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "num",
    attrs: {
      "role": "cell"
    }
  }, [_c('span', {
    staticClass: "symbol"
  })]);

}]

export { render, staticRenderFns }