var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--mypage"
  }, [_c('main-header'), _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "mypage-container"
  }, [_vm._m(0), _c('nav', {
    staticClass: "mypage-navigation"
  }, [_c('mypage-navigation')], 1), _c('div', {
    staticClass: "mypage-contents"
  }, [_vm._m(1), _c('div', {
    staticClass: "mypage-contents__body"
  }, [_c('div', {
    staticClass: "cancel-list-container"
  }, [_c('div', {
    staticClass: "cancel-list__body"
  }, [_c('section', {
    staticClass: "order-cards"
  }, _vm._l(_vm.purchases, function (purchase) {
    return _c('div', {
      staticClass: "order-card"
    }, [_c('div', {
      staticClass: "order-card__info"
    }, [_c('div', {
      staticClass: "order-card__row"
    }, [_c('span', {
      staticClass: "order-card__title"
    }, [_vm._v("상품번호(주문번호)")]), _c('span', {
      staticClass: "order-card__text"
    }, [_vm._v(_vm._s(purchase.purchaseNo) + " (" + _vm._s(purchase.order.orderNo) + ")")])]), _c('div', {
      staticClass: "order-card__row"
    }, [_c('span', {
      staticClass: "order-card__title"
    }, [_vm._v("상품명")]), _c('span', {
      staticClass: "order-card__text"
    }, [_vm._v(_vm._s(purchase.product.name))])]), _c('div', {
      staticClass: "order-card__row"
    }, [_c('span', {
      staticClass: "order-card__title"
    }, [_vm._v("옵션")]), _c('span', {
      staticClass: "order-card__text"
    }, [_vm._v(_vm._s(purchase.name))])]), _c('div', {
      staticClass: "order-card__row"
    }, [_c('span', {
      staticClass: "order-card__title"
    }, [_vm._v("상품금액(개수)")]), _c('span', {
      staticClass: "order-card__text"
    }, [_vm._v(_vm._s(purchase.price.format()) + "원 (" + _vm._s(purchase.amount.format()) + "개)")])]), _c('div', {
      staticClass: "order-card__row"
    }, [_c('div', {
      staticClass: "order-card__col"
    }, [_c('span', {
      staticClass: "order-card__title"
    }, [_vm._v("할인금액")]), _c('span', {
      staticClass: "order-card__text"
    }, [_vm._v("- " + _vm._s(purchase.discountPrice.format()) + "원")])])])]), _c('div', {
      staticClass: "order-card__state"
    }, [_c('div', {
      staticClass: "order-card__row"
    }, [_c('span', {
      staticClass: "order-card__title"
    }, [_vm._v("주문상태")]), _c('span', {
      staticClass: "order-card__text"
    }, [_c('font', {
      attrs: {
        "color": "red"
      }
    }, [_vm._v(_vm._s(purchase.claimStatusMessage))])], 1)]), _c('div', {
      staticClass: "order-card__row"
    }, [_c('span', {
      staticClass: "order-card__title"
    }, [_vm._v("교환사유")]), _c('span', {
      staticClass: "order-card__text"
    }, [_c('font', {
      attrs: {
        "color": "red"
      }
    }, [_vm._v(_vm._s(purchase.claimReason))])], 1)])])]);
  }), 0), _vm.purchases.length < _vm.summary.totalCount ? _c('div', {
    staticClass: "bottom-button"
  }, [_c('button', {
    staticClass: "button button--border-primary",
    on: {
      "click": _vm.loadmore
    }
  }, [_c('span', [_vm._v("더보기")])])]) : _vm._e(), !_vm.purchases.length ? _c('div', [_vm._v(" 교환내역이 없습니다 ")]) : _vm._e()])])])])])])]), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mypage-title"
  }, [_c('h1', {
    staticClass: "text"
  }, [_vm._v("마이페이지")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mypage-contents__head"
  }, [_c('h2', {
    staticClass: "mypage-contents__title"
  }, [_vm._v("교환내역")])]);

}]

export { render, staticRenderFns }