<template>
	<div class="wrap wrap--mypage">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
			<div class="container">
				<div class="mypage-container">
                    <!-- 배너 타이틀 -->
                    <div class="mypage-title">
                        <h1 class="text">마이페이지</h1>
                    </div>
					<!-- 네비게이션 메뉴 -->
					<nav class="mypage-navigation">
						<mypage-navigation></mypage-navigation>
					</nav>
					<!-- 페이지 콘텐츠 -->
					<div class="mypage-contents">
						<div class="mypage-contents__head">
                            <h2 class="mypage-contents__title">샘플신청목록</h2>
						</div>
						<div v-if="!accessToken && !nonMember" class="mypage-contents__body">
							<div class="mypege-login-frame">
								<h2 class="login-title">비회원 주문조회</h2>
								<div class="login-container">
									<div class="login-rowgroup">
										<div class="login-row">
											<input v-model="sender.name" type="text" placeholder="주문자명" class="input">
										</div>
										<div class="login-row">
											<input v-model="sender.phone" type="text" placeholder="연락처" class="input" @input="sender.phone = sender.phone.replace(/[^0-9\-]/, '')">
										</div>
										<div class="login-row login-row--submit">
											<button class="button button--red" @click="search"><span>검색</span></button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-if="accessToken || nonMember" class="mypage-contents__body">
                            <div class="order-list-container">
                                <div class="order-list__head">
                                    <ul class="tab-list tab-list--tooth">
                                    	<li class="on"><button class="button" type="button">전체</button></li>
                                    	<!-- <li><button class="button" type="button">오늘</button></li>
                                    	<li><button class="button" type="button">1주일</button></li>
                                    	<li><button class="button" type="button">1개월</button></li>
                                    	<li><button class="button" type="button">3개월</button></li>
                                    	<li><button class="button" type="button">6개월</button></li>
                                    	<li><button class="button" type="button">12개월</button></li> -->
                                    </ul>
                                    <div v-show="false" class="order-list-search">
                                        <form class="order-list-search__form" action="index.html" method="post">
                                            <div class="order-list-search__box">
                                                <input class="input" type="text" name="" value="" placeholder="상품명을 검색하세요.">
                                                <button class="button" type="submit" name="button"></button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="order-list__body">
									<section class="card-list-container">
										<section class="card-list-fields">
											<span class="field">상품이미지</span>
											<span class="field">신청번호/상품명</span>
											<span class="field">신청날짜</span>
											<span class="field">상태</span>
										</section>
										<section class="card-list-blocks">
											<div v-for="purchase in purchases" class="card-list">
												<div class="card-list__records">
													<div class="record">
														<div class="thumb">
															<img :src="purchase.product.thumb">
														</div>
													</div>
													<div class="record">
														<div class="info">
															<span class="text">주문일자: {{ purchase.createDate.toDate() }}</span>
															<span class="text">상품주문번호: {{ purchase.purchaseNo }}</span>
															<span class="name"><a :href="`/shop/products/view?_product=${purchase._product}`">[{{ purchase.product.code }}] {{ purchase.product.name }}</a></span>
															<span v-show="false"class="option-lists">
																{{ purchase.name }}
															</span>
															<span class="number">
																{{ purchase.orderNo }}
															</span>
															<span v-show="false" class="price">결제금액 : {{ (purchase.salePrice * purchase.amount).format() }}원</span>
														</div>
													</div>
													<div v-if="purchase.delivery" class="record">
														<div>{{ purchase.delivery.name }}</div>
														<div>{{ purchase.delivery.number }}</div>
													</div>
													<div class="record">
														<div v-if="!purchase.claimStatus">{{ purchase.orderStatusMessage }}</div>
														<div v-else>{{ purchase.claimStatusMessage }}</div>
													</div>
												</div>
												<div class="card-list__buttons">
													<div class="left">
														<ul class="func-lists func-lists--border">
															<li v-show="false" class="func-list"><a role="button" onclick="return false;">주문내역삭제</a></li>
															<li class="func-list"><a role="button" onclick="return false;" @click="details(purchase._order)">샘플신청상세보기</a></li>
															<!--
															스타일 적용을 위해 주석처리함 2021/01/18
															스타일로 숨김처리 -> DOM 출력 제한으로 수정필요
															(마지막 형제 리스트가 남아있으면 css상에서 last-child로 인식됨.)
															 -->
															<!-- <li v-show="false" class="func-list"><a role="button" onclick="return false;">현금영수증출력</a></li> -->
														</ul>
													</div>
													<div class="right">
														<ul class="func-lists func-lists--padding">
															<li v-if="returnEnabled(purchase)" class="func-list"><a class="button button--secondary" role="button" @click="claim(purchase)">반품요청</a></li>
															<li v-if="exchangeEnabled(purchase)" class="func-list"><a class="button button--secondary" role="button" @click="claim(purchase)">교환요청</a></li>
															<li v-if="cancelEnabled(purchase)" class="func-list"><a class="button button--lightgrey" role="button" @click="claim(purchase)">주문취소요청</a></li>
															<li v-if="accessToken && reviewEnabled(purchase)" class="func-list"><a class="button button--lightgrey" role="button" @click="review(purchase)">구매후기등록</a></li>
															<li v-if="accessToken" class="func-list"><a class="button button--lightgrey" role="button" @click="inquire(purchase)">상품문의</a></li>
														</ul>
													</div>
												</div>
											</div>
											<div v-if="!purchases.length" style="text-align:center">
												주문내역이 없습니다
											</div>
										</section>
										<div v-if="purchases.length < summary.totalCount" class="bottom-button">
											<button class="button button--border-primary" @click="loadmore"><span>더보기</span></button>
										</div>
									</section>
                                </div>
                            </div>
						</div>
					</div>
				</div>
			</div>

			<!-- 주문내역삭제 모달 -->
			<modal-order-delete v-if="showModalOrderDelete" @close="closeModalOrderDelete"></modal-order-delete>
			<!-- 샘플신청상세보기 모달 -->
			<modal-order-details ref="modal-order-details"></modal-order-details>

			<!-- 주문내역 취소 & 반품 & 교환 & 상품문의 모달-->
			<modal-claim ref="modal-claim"></modal-claim>

			<modal-review ref="modal-review"></modal-review>

			<modal-inquiry ref="modal-inquire"></modal-inquiry>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from "@/components/client/inc/main-header.vue";
import MainFooter from "@/components/client/inc/main-footer.vue";
import ModalReview from "@/components/client/mypage/modal-review.vue";
import ModalClaim from "@/components/client/mypage/modal-claim.vue";
import ModalInquiry from "@/components/client/shop/modal-inquiry.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";
import ModalOrderDelete from "@/components/client/mypage/modal-order-delete.vue";
import ModalOrderDetails from "@/components/client/mypage/modal-order-details.vue";

export default{
	components: {
		MainHeader,
		MainFooter,
		ModalReview,
		ModalInquiry,
		MypageNavigation,
		ModalOrderDelete,
		ModalOrderDetails,
		ModalClaim
	},
	data: function() {
		return{
			nonMember: false,
			sender: {
				name: null,
				phone: null
			},

			showModalOrderDelete: false,
			showModalOrderDetail: true,
			showModalOrderInquiry: false,

			skip: 0,
			limit: 10,
			summary: { totalCount: 0 },
			purchases: []
		}
	},
	mounted(){
		this.init();
	},
	methods: {
		async init(){
			try{
				if(this.accessToken) await this.getPurchases();
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
			}
		},

		async search(){
			try{
				if(!this.sender.name) throw new Error("주문자명을 입력해주세요");
				if(!this.sender.phone) throw new Error("연락처를 입력해주세요");

				await this.getPurchases();
				this.nonMember = true;
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message);
			}
		},

		////////////////////////////////////////////////////////////////////////
		// 주문 상세보기
		////////////////////////////////////////////////////////////////////////
		async details(_order){
			try{
				this.$refs['modal-order-details'].order = await this.getOrder(_order);
				this.$refs['modal-order-details'].open();
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
			}
		},

		////////////////////////////////////////////////////////////////////////
		// 취소, 반품, 교환
		////////////////////////////////////////////////////////////////////////
		claim(purchase){
			this.$refs['modal-claim'].purchase = purchase;
			this.$refs['modal-claim'].open();
		},

		////////////////////////////////////////////////////////////////////////
		// 상품문의
		////////////////////////////////////////////////////////////////////////
		inquire(purchase){
			this.$refs['modal-inquire'].product = purchase.product;
			this.$refs['modal-inquire'].open();
		},

		////////////////////////////////////////////////////////////////////////
		// 더보기
		////////////////////////////////////////////////////////////////////////
		async loadmore(){
			try{
				this.skip = this.purchases.length;
				await this.getPurchases();
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message);
			}
		},

		////////////////////////////////////////////////////////////////////////
		// 반품가능여부
		////////////////////////////////////////////////////////////////////////
		returnEnabled(purchase){
			if(purchase.claimStatus) return false;
			switch(purchase.orderStatus){
				case "shipping-confirm":
				case "shipping-delay":
				case "shipping-cancel":
				case "delivery-complete": {
					return true;
				}
			}
			return false;
		},

		////////////////////////////////////////////////////////////////////////
		// 교환가능여부
		////////////////////////////////////////////////////////////////////////
		exchangeEnabled(purchase){
			if(purchase.claimStatus) return false;
			switch(purchase.orderStatus){
				case "shipping-confirm":
				case "shipping-delay":
				case "shipping-cancel":
				case "delivery-complete": {
					return true;
				}
			}
			return false;
		},

		////////////////////////////////////////////////////////////////////////
		// 취소가능여부
		////////////////////////////////////////////////////////////////////////
		cancelEnabled(purchase){
			if(purchase.claimStatus) return false;
			switch(purchase.orderStatus){
				case "order-nonpayment":
				case "order-payment":
				case "order-confirm": {
					return true;
				}
			}
			return false;
		},

		////////////////////////////////////////////////////////////////////////
		// 리뷰등록
		////////////////////////////////////////////////////////////////////////
		review(purchase){
			this.$refs['modal-review'].purchase = purchase;
			this.$refs['modal-review'].open();
		},

		////////////////////////////////////////////////////////////////////////
		// 리뷰가능여부
		////////////////////////////////////////////////////////////////////////
		reviewEnabled(purchase){
			if(purchase.orderStatus == "delivery-complete" && !purchase._review){
				if(purchase.claimStatus === undefined || purchase.claimStatus === null || purchase.claimStatus == "exchange-complete") return true;
			}
			return false;
		},

		//////////////////////////////////////////////////////////////
		// 구매내역 조회
		//////////////////////////////////////////////////////////////
		getPurchases(){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.get(`/api/v1/me/purchases`, {
						headers: {
							skip: this.skip,
							limit: this.limit
						},
						params: Object.assign({}, this.accessToken ? {} : { "sender.name": this.sender.name, "sender.phone": this.sender.phone })
					});

					this.purchases = res.data.purchases;

					resolve();
				}
				catch(error){
					reject(error);
				}
			});
		},

		//////////////////////////////////////////////////////////////
		// 구매내역 수정
		//////////////////////////////////////////////////////////////
		putPurchase(purchase){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.put(`/api/v1/me/purchases/${purchase._id}`, purchase);

					resolve(res.data.purchase);
				}
				catch(error){
					reject(error);
				}
			});
		},

		//////////////////////////////////////////////////////////////
		// 주문정보 조회
		//////////////////////////////////////////////////////////////
		getOrder(_order){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.get(`/api/v1/me/orders/${_order}`);

					resolve(res.data.order);
				}
				catch(error){
					reject(error);
				}
			});
		}
	},
	computed: {
		accessToken(){
			return this.$store.state.accessToken;
		}
	}
}
</script>
