<template>
	<div class="wrap wrap--mypage">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
			<div class="container">
				<div class="mypage-container">
                    <!-- 배너 타이틀 -->
                    <div class="mypage-title">
                        <h1 class="text">마이페이지</h1>
                    </div>
					<!-- 네비게이션 메뉴 -->
					<nav class="mypage-navigation">
						<mypage-navigation></mypage-navigation>
					</nav>
					<!-- 페이지 콘텐츠 -->
					<div class="mypage-contents">
						<div class="mypage-contents__head">
                            <h2 class="mypage-contents__title">교환내역</h2>
						</div>
						<div class="mypage-contents__body">
                            <div class="cancel-list-container">
                                <!-- <div class="cancel-list__head"></div> -->
                                <div class="cancel-list__body">
                                    <section class="order-cards">
                                        <div v-for="purchase in purchases" class="order-card">
                                            <div class="order-card__info">
                                                <div class="order-card__row">
                                                    <span class="order-card__title">상품번호(주문번호)</span>
                                                    <span class="order-card__text">{{ purchase.purchaseNo }} ({{ purchase.order.orderNo }})</span>
                                                </div>
                                                <div class="order-card__row">
                                                    <span class="order-card__title">상품명</span>
                                                    <span class="order-card__text">{{ purchase.product.name }}</span>
                                                </div>
												<div class="order-card__row">
                                                    <span class="order-card__title">옵션</span>
                                                    <span class="order-card__text">{{ purchase.name }}</span>
                                                </div>
                                                <div class="order-card__row">
                                                    <span class="order-card__title">상품금액(개수)</span>
                                                    <span class="order-card__text">{{ purchase.price.format() }}원 ({{ purchase.amount.format() }}개)</span>
                                                </div>
                                                <div class="order-card__row">
                                                    <div class="order-card__col">
                                                        <span class="order-card__title">할인금액</span>
                                                        <span class="order-card__text">- {{ purchase.discountPrice.format() }}원</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="order-card__state">
                                                <div class="order-card__row">
                                                    <span class="order-card__title">주문상태</span>
                                                    <span class="order-card__text"><font color="red">{{ purchase.claimStatusMessage }}</font></span>
                                                </div>
                                                <div class="order-card__row">
                                                    <span class="order-card__title">교환사유</span>
                                                    <span class="order-card__text"><font color="red">{{ purchase.claimReason }}</font></span>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
									<div v-if="purchases.length < summary.totalCount" class="bottom-button">
										<button class="button button--border-primary" @click="loadmore"><span>더보기</span></button>
									</div>
									<div v-if="!purchases.length">
										교환내역이 없습니다
									</div>
                                </div>
                            </div>
						</div>
					</div>
				</div>
			</div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from "../../../components/client/inc/main-header.vue";
import MainFooter from "../../../components/client/inc/main-footer.vue";
import MypageNavigation from "../../../components/client/mypage-navigation.vue";

export default{
	components: {
		MainHeader,
		MainFooter,
		MypageNavigation
	},
	data: () => {
		return {
			skip: 0,
			limit: 0,
			summary: { totalCount: 0 },
			purchases: []
		};
	},
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		async init() {
			try{
				await this.getPurchases();
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message.toString());
			}
		},

		////////////////////////////////////////////////////////////////////////
		// 더보기
		////////////////////////////////////////////////////////////////////////
		async loadmore(){
			try{
				this.skip = this.purchases.length;
				await this.getPurchases();
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message);
			}
		},

		//////////////////////////////////////////////////////////////
		// 구매내역 조회
		//////////////////////////////////////////////////////////////
		getPurchases(){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.get(`/api/v1/me/purchases`, {
						headers: {
							skip: this.skip,
							limit: this.limit
						},
						params: {
							claimStatuses: ["exchange-request", "exchange-complete", "exchange-reject"]
						}
					});

					this.purchases = res.data.purchases;

					resolve();
				}
				catch(error){
					reject(error);
				}
			});
		},
	},
}
</script>
