<template>
	<div class="wrap wrap--mypage">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
			<div class="container">
				<div class="mypage-container">
                    <!-- 배너 타이틀 -->
                    <div class="mypage-title">
                        <h1 class="text">마이페이지</h1>
                    </div>
					<!-- 네비게이션 메뉴 -->
					<nav class="mypage-navigation">
						<mypage-navigation></mypage-navigation>
					</nav>
					<!-- 페이지 콘텐츠 -->
					<div v-if="me" class="mypage-contents">
						<div class="mypage-contents__head">
                            <h2 class="mypage-contents__title">내정보</h2>
						</div>
						<div class="mypage-contents__body">
							<div role="table" class="myinfo-table">
								<div role="rowgroup">
									<div role="row">
										<div role="columnheader">아이디(이메일)</div>
										<div role="cell">
											{{ me.username }}
											<!-- 이메일 폼 -->
											<!-- <div class="form-email">
												<input class="input" type="text">
												<span class="text">@</span>
												<input class="input" type="text">
												<select class="select">
													<option value="">직접입력</option>
													<option value="naver.com">naver.com</option>
													<option value="daum.net">daum.net</option>
													<option value="google.com">google.com</option>
												</select>
											</div> -->
										</div>
									</div>
									<div role="row">
										<div role="columnheader">이름</div>
										<div role="cell">
											<input v-model="me.name" type="text" class="input">
										</div>
									</div>
									<div role="row">
										<div role="columnheader">휴대전화</div>
										<div role="cell">
											<!-- 연락처 폼 -->
											<div class="form-tel">
												<select v-model="phone1" class="select" name="">
													<option value="010">010</option>
													<option value="011">011</option>
													<option value="016">016</option>
													<option value="017">017</option>
													<option value="019">019</option>
												</select>
												<span class="text"></span>
												<input v-model="phone2" class="input" type="tel" name="" value="">
												<span class="text"></span>
												<input v-model="phone3" class="input" type="tel" name="" value="">
											</div>
										</div>
									</div>
									<div role="row">
										<div role="columnheader">비밀번호</div>
										<div role="cell">
											<input v-model="password" class="input" type="password" name="" value="">
											<br>
											<small class="text-grey">(영문대소문자/숫자/특수문자 중 2가지 이상 조합, 8 - 16자)</small>
										</div>
									</div>
									<div role="row">
										<div role="columnheader">비밀번호 확인</div>
										<div role="cell">
											<input v-model="password2" class="input" type="password" name="" value="">
										</div>
									</div>
								</div>
							</div>
							<div class="bottom-button">
								<button class="button button--border-primary"><span>취소</span></button>
								<button class="button button--primary" @click="save"><span>확인</span></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
const CryptoAES = require('../../../plugins/crypto-aes');

import MainHeader from "../../../components/client/inc/main-header.vue";
import MainFooter from "../../../components/client/inc/main-footer.vue";
import MypageNavigation from "../../../components/client/mypage-navigation.vue";

export default{
	components: {
		MainHeader,
		MainFooter,
		MypageNavigation
	},
	data: () => {
		return {
			me: null,

			phone1: null,
			phone2: null,
			phone3: null,

			password: null,
			password2: null
		};
	},
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		async init(){
			try{
				if(!this.accessToken){
					this.$router.push('/login');
					return;
				}

				await this.getMyInfo();
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message.toString());
			}
		},

		async save(){
			try{
				if(!this.phone1 || !this.phone2 || !this.phone3) throw new Error("연락처를 입력해주세요");
				if((this.password || this.password2) && (this.password != this.password2)) throw new Error("비밀번호 및 비밀번호확인이 일치하지 않습니다");

				await this.putMyInfo(Object.assign(this.me, {
					password: CryptoAES.encrypt(this.password),
					phone: this.phone,
				}));

				alert("수정되었습니다");
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message.toString());
			}
		},

		getMyInfo(){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.get('/api/v1/me/info');

					this.me = res.data.user;

					var [phone1, phone2, phone3] = this.me.phone ? this.me.phone.phoneNumberFormat().split('-') : [];
					Object.assign(this.$data, {
						phone1,
						phone2,
						phone3
					});

					resolve();
				}
				catch(error){
					reject(error);
				}
			});
		},

		putMyInfo(user){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.put("/api/v1/me/info", user);

					resolve();
				}
				catch(error){
					reject(error);
				}
			});
		}
	},
	computed: {
		accessToken(){
			return this.$store.state.accessToken;
		},
		phone(){
			return (this.phone1 && this.phone2 && this.phone3) ? [this.phone1, this.phone2, this.phone3].join('-') : undefined;
		}
	}
}
</script>
