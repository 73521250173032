<template id="modal-template">
    <transition v-if="show" name="modal" appear>
        <div class="modal-container">
            <div class="modal-wrapper">
                <div class="modal modal--11">
                    <div class="modal__head">
                        <h1 class="modal__title">샘플신청상세보기</h1>
                        <button class="modal__close" type="button" @click="close">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <path d="M0.222,1.636 L1.636,0.221 L15.778,14.363 L14.364,15.778 L0.222,1.636 Z"/>
                                <path d="M14.364,0.221 L15.778,1.636 L1.636,15.778 L0.222,14.363 L14.364,0.221 Z"/>
                            </svg>
                        </button>
                    </div>
                    <div class="modal__body modal__body--no-padding-top">
                        <div class="order-detail-container">
                            <div class="order-detail__caption">
                                <ul class="order-detail__caption-info">
                                    <li>신청번호 : {{ order.orderNo }}</li>
                                    <li>신청일자 : {{ order.createDate.toDate() }}</li>
                                </ul>
                                <!-- <div class="order-detail__caption-buttons">
                                    <a href="" class="button button--border-solid" onclick="return false"><span>현금영수증출력</span></a>
                                </div> -->
                            </div>
                            <section class="order-detail__rows">
                                <section class="order-detail__row">
                                    <div class="order-detail__block">
                                        <!--
                                        #샘플신청상세보기 테이블
                                        PC-모바일 테이블 제목 매칭
                                        PC전용 columnheader와 모바일전용 mobile-title
                                        -->
                                        <div class="order-detail-table" role="table">
                                            <div role="rowgroup">
                                                <div role="row">
                                                    <div role="columnheader" class="order">샘플신청번호</div>
                                                    <div role="columnheader" class="name">상품명</div>
                                                    <div role="columnheader" class="state">상태</div>
                                                </div>
                                            </div>
                                            <div role="rowgroup">
                                                <div v-for="purchase in order.purchases" role="row">
                                                    <div role="cell" class="order">{{ purchase.purchaseNo }}</div>
                                                    <div role="cell" class="name">
                                                        <!-- 추가정보필요 시 사용하세요
                                                        <span class="text">[제품코드]</span> -->
                                                        {{ purchase.product.name }}
                                                        <span class="option-lists">
                                                            {{ purchase.name }}
                                                        </span>
                                                    </div>
                                                    <div role="cell" class="state">
                                                        <div class="mobile-title">상태</div>
                                                        <div class="data">
                                                            <span v-if="purchase.claimStatus" class="color-red">{{ purchase.claimStatusMessage }}</span>
                                                            <span v-else>{{ purchase.orderStatusMessage }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section class="order-detail__row">
                                    <div class="order-detail__block">
                                        <!-- 모바일전용 배송지 정보 변경 버튼 -->
                                        <div class="order-detail-right sp">
                                            <a class="button button--border" href="" onclick="return false;">배송지 정보 변경</a>
                                        </div>
                                        <!-- 배송지 정보 테이블 -->
                                        <div class="order-detail-table" role="table">
                                            <div role="rowgroup">
                                                <div role="row">
                                                    <div role="columnheader" class="info"><b>배송지 정보</b></div>
                                                    <div role="columnheader" class="data">
                                                        <button v-if="editable && mode == 'view'" class="button button--border" @click="edit">변경</button>
                                                        <button v-if="editable && mode == 'edit'" class="button button--border" @click="save">저장</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div role="rowgroup">
                                                <div role="row">
                                                    <div role="cell" class="info"><b>이름</b></div>
                                                    <div role="cell" class="data">{{ order.receiver.name }}</div>
                                                </div>
                                                <div role="row">
                                                    <div role="cell" class="info"><b>주소</b></div>
                                                    <div v-if="mode == 'view'" role="cell" class="data">
                                                        [{{ order.receiver.postcode }}] {{ order.receiver.address1 }} {{ order.receiver.address2 }}
                                                    </div>
                                                    <div v-else role="cell" class="data">
                                                        <div class="form-address">
                                                            <div class="row">
                                                                <input v-model="order.receiver.postcode" class="input" placeholder="우편번호" readonly @click="searchPostcode">
                                                                <button type="button" class="button button--border-primary" @click="searchPostcode"><span>주소찾기</span></button>
                                                            </div>
                                                            <div class="row">
                                                                <input v-model="order.receiver.address1" class="input" placeholder="기본주소" readonly @click="searchPostcode">
                                                                <input v-model="order.receiver.address2" class="input" placeholder="상세주소">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div role="row">
                                                    <div role="cell" class="info"><b>연락처</b></div>
                                                    <div role="cell" class="data">{{ order.receiver.phone }}</div>
                                                </div>
                                                <div role="row">
                                                    <div role="cell" class="info"><b>요청사항</b></div>
                                                    <div role="cell" class="data">
                                                        <div class="flexbox">
                                                            <span v-if="mode == 'view'">{{ order.requestMessage }}</span>
                                                            <input v-if="mode == 'edit'" v-model="order.requestMessage">
                                                            <button v-if="editable && mode == 'view'" class="button button--border-solid" @click="edit">변경</button>
                                                            <button v-if="editable && mode == 'edit'" class="button button--border-solid" @click="save">저장</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-show="false" class="order-detail__block">
                                        <!-- 결제정보 테이블 -->
                                        <div class="order-detail-table" role="table">
                                            <div role="rowgroup">
                                                <div role="row">
                                                    <div role="columnheader" class="info"><b>결제정보</b></div>
                                                    <div role="columnheader" class="data"></div>
                                                </div>
                                            </div>
                                            <div role="rowgroup">
                                                <div role="row">
                                                    <div role="cell" class="info"><b>최종결제금액</b></div>
                                                    <div role="cell" class="data">{{ order.totalPrice.format() }} 원</div>
                                                </div>
                                                <div role="row">
                                                    <div role="cell" class="info"><b>결제 방법</b></div>
                                                    <div role="cell" class="data">
                                                        <span v-if="order.paymentMethod == 'card'">신용카드</span>
                                                        <span v-if="order.paymentMethod == 'account'">무통장입금</span>
                                                        <span v-if="order.paymentMethod == 'kakao'">카카오페이</span>
                                                    </div>
                                                </div>
                                                <div role="row">
                                                    <div role="cell" class="info"><b>상품금액</b></div>
                                                    <div role="cell" class="data">{{ order.productPrice.format() }} 원</div>
                                                </div>
                                                <div role="row">
                                                    <div role="cell" class="info"><b>할인금액(합산)</b></div>
                                                    <div role="cell" class="data">- {{ order.discountPrice.format() }}원</div>
                                                </div>
                                                <!-- <div role="row">
                                                    <div role="cell" class="info"><b>적립금</b></div>
                                                    <div role="cell" class="data">0원</div>
                                                </div> -->
                                                <div role="row">
                                                    <div role="cell" class="info"><b>배송비</b></div>
                                                    <div role="cell" class="data">{{ order.deliveryPrice.format() }}원</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </section>
                        </div>
                    </div>
                    <div class="modal__foot">
                        <div class="modal__buttons">
                            <button class="button button--primary" type="button" @click="close"><span>확인</span></button>
                        </div>
                    </div>
                </div>
            </div>
            <daum-postcode ref="daum-postcode" @input="changePostcode"></daum-postcode>
        </div>
    </transition>
</template>

<script>
import DaumPostcode from '@/components/plugins/daum/daum-postcode.vue';

export default {
    components: {
        DaumPostcode
    },
    data: () => {
        return {
            show: false,
            order: null,
            mode: "view"
        }
    },
    methods: {
        open(){
            document.querySelector('html').setAttribute('data-modal', 'opened');
            this.show = true;
        },
        close(){
            document.querySelector('html').removeAttribute('data-modal');
            this.show = false;
        },

        edit(){
            if(this.editable){
                this.mode = "edit";
            }
        },

        async save(){
            try{
                await this.putOrder(this.order);
                this.mode = "view";
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        searchPostcode(){
            this.$refs['daum-postcode'].open();
        },
        changePostcode(data){
            if(this.order){
                this.order.receiver.postcode = data.postcode;
                this.order.receiver.address1 = data.address;
            }
        },
        async putOrder(order){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.put(`/api/v1/me/orders/${order._id}`, order);

                    resolve(res.data);
                }
                catch(error){
                    reject(error);
                }
            });
        }
    },
    computed: {
        editable(){
            if(this.order){
                // 배송상태 이후인 구매건이 없을때에만, 배송지 편집이 가능
                var processing = this.order.purchases.find((purchase) => !['order-nonpayment', 'order-payment', 'order-confirm'].includes(purchase.orderStatus));
                if(!processing) return true;
            }
            return false;
        }
    }
}
</script>
